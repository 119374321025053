import { cn } from '@bem-react/classname';
import React, { useEffect, useRef } from 'react';
import './flow.scss';

const classItem = cn('Flow');

const ScreenSize = {
    S: '(min-width: 0) and (max-width: 1023px)',
    M: '(min-width: 1024px) and (max-width: 1360px)',
    L: '(min-width: 1360px) and (max-width: 10000px)',
}

const useMediaQuery = (query, func, deps=[]) => {
    useEffect(() => {
        const q = matchMedia(query);
        func(q.matches);

        const checker = (e) => {
            func(e.matches);
        };

        q.addEventListener('change', checker);


        return () => {
            q.removeEventListener('change', checker);
        }
    }, [query, func, ...deps]);
}

const useClassOnBreakpoint = (breakpoints, cssClass, elRef) => {
    useMediaQuery(breakpoints.sort().join(', '), (matches) => {
        elRef.current?.classList.toggle(cssClass, matches);
    }, [elRef]);
}

const ContentFlow = ({horisontal=[], vertical=[], children, centerred}) => {

    const elementRef = useRef();

    useClassOnBreakpoint(horisontal, 'Flow_horisontal', elementRef);
    useClassOnBreakpoint(vertical, 'Flow_vertical', elementRef);


    if (!horisontal && !vertical) {
        return children;
    }

    return <div ref={el => {return elementRef.current = el}} className={classItem('', {centerred})}>{children}</div>
}
const Horisontal = ({spacedEvenly=false, spaced=false, leftAligned=false, centerred=false, children, style}) => {
    return <div style={style} className={classItem('', {horisontal: true, spaced, spacedEvenly, leftAligned, centerred})}>{children}</div>
}

const Vertical = ({spaced=false, leftAligned=false, centerred=false, children, style}) => {
    return <div style={style} className={classItem('', {vertical: true, spaced, leftAligned ,centerred})}>{children}</div>
}

export {
    Horisontal,
    Vertical,
    ContentFlow,
    ScreenSize,
    useMediaQuery
};

import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import NewsArticle from "../components/NewsArticle";
import { ContentFlow, ScreenSize } from "../landingnewcomponents/Flow";
import Section from "../landingnewcomponents/Section/Landing";
import Title, { TITLE_COLORS } from "../landingnewcomponents/Title/Landing";
import Layout from "../layouts/layout";
import "./../common/styles.scss";

const NewsPage = (props) => {

    const news_posts_data = useStaticQuery(graphql`
    {
      posts: allWpPost(limit: 10, filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 117}}}}}) {
        nodes {
          title
          date
          link
          databaseId
          content
        }
      }      
    }
  `)

    return (
        <Layout location={props.location}>
            <Section>
                <Breadcrumbs
                    items={[{ title: "Viewst", url: "/" }, { title: "News", url: "/news/" }]}
                />
                <ContentFlow horisontal={[ScreenSize.L, ScreenSize.M]} vertical={[ScreenSize.S]}>
                    <Title color={TITLE_COLORS.Pink}>
                        <FormattedMessage id="pages.news.sections.main.title" defaultMessage="News" />
                    </Title>
                </ContentFlow>
                <div>
                    {news_posts_data.posts.nodes.map((article, idx) => {
                        return <NewsArticle date={article.date} title={article.title} content={article.content} key={idx} />;
                    })}
                </div>
            </Section>
        </Layout>
    )
}

export default NewsPage;

import { cn } from "@bem-react/classname";
import { format } from "date-fns";
import React from "react";
import './news-article.scss';

const NewsArticle = ({ date, content, title }) => {
    const newsArticleClass = cn('NewsArticle');
    const formattedDate = format(new Date(date), 'LLLL d, yyyy');

    return (
        <div className={newsArticleClass()}>
            <div className={newsArticleClass('Date')}>{formattedDate}</div>
            <div className={newsArticleClass('Content')}>
                {title && <h1>{title}</h1>}
                <div className={newsArticleClass('ContentData')} dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        </div>
    );
}

export default NewsArticle;
